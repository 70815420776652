<template>
    <div class="condition-filter-option">
        <el-select
                placeholder="Select"
                class="select"
                v-model="selected"
                @change="onSelectChange($event)"
        >
            <el-option
                    v-for="option in options"
                    :key="`select_option_${option.value}`"
                    :label="option.title"
                    :value="option.value"
            >
                <span class="select-option-text">{{ option.title }}</span>
            </el-option>
        </el-select>

        <div class="condition-row" v-if="CONDITION_TYPES.BT.value === selected">
            <img class="icon" src="../../assets/img/admin/enter-arrow.png" alt/>
            <el-input class="from" v-model="from" @change="onInputChange('from')($event)"/>
            <span class="text">and</span>
            <el-input class="to" v-model="to" @change="onInputChange('to')($event)"/>
        </div>

        <div class="condition-row" v-if="CONDITION_TYPES.BT.value !== selected">
            <img class="icon" src="../../assets/img/admin/enter-arrow.png" alt/>
            <el-input class="from" v-model="from" @change="onInputChange('from')($event)"/>
        </div>

    </div>
</template>

<script>
    import {CONDITION_TYPES} from '../../constants';

    export default {
        name: 'ConditionFilterOptionProjects',
        props: [
            'getData',
            'value',
            'onChange',
            'title',
        ],
        data() {
            return {
                options: Object.values(CONDITION_TYPES),
                selected: CONDITION_TYPES.EQ.value,
                CONDITION_TYPES,
                from: '',
                to: '',
            };
        },
        methods: {
            onSelectChange($event) {
                const {from, to} = this;
                this.onChange(
                    this.title.toLowerCase(),
                    {
                        action: $event,
                        value: CONDITION_TYPES.BT.value === $event
                            ? {from, to}
                            : from,
                    }
                );
            },
            onInputChange(name) {
                const {selected} = this;
                const oppositeName = name === 'from' ? 'to' : 'from';

                return $event => this.onChange(
                    this.title.toLowerCase(),
                    {
                        action: selected,
                        value: CONDITION_TYPES.BT.value === selected
                            ? {[name]: $event, [oppositeName]: this[oppositeName]}
                            : $event,
                    }
                );
            }
        }
    }
</script>

<style lang="less">
    .condition-filter-option {
        .select {
            width: 100%;

            input {
                width: 100%;
            }
        }

        .condition-row {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .icon {
                min-width: 13px;
                height: 12px;
                margin-right: 10px;
                margin-left: 5px;
            }

            .from, .to {
                input {
                    width: inherit;
                }
            }

            .text {
                font-size: 11px;
                color: #A3BAC6;
                padding-left: 9px;
                padding-right: 9px;
            }
        }
    }
</style>