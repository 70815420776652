<template>
  <svg
    class="icon-arrow__down"
    xmlns="http://www.w3.org/2000/svg"
    width="9.709"
    height="5.395"
    viewBox="0 0 9.709 5.395"
  >
    <path
      id="Path_2500"
      data-name="Path 2500"
      d="M15.582,33.5a.328.328,0,0,0-.463,0l-4.075,4.083L6.959,33.5a.328.328,0,1,0-.463.463L10.8,38.267a.32.32,0,0,0,.232.1.334.334,0,0,0,.232-.1l4.307-4.307A.321.321,0,0,0,15.582,33.5Z"
      transform="translate(-6.168 -33.168)"
      fill="#778d9d"
      stroke="#778d9d"
      stroke-width="0.4"
    />
  </svg>
</template>
<script>
export default {
  name: "ArrowDownIcon"
};
</script>
<style lang="less" scoped>
.icon-arrow__down {
  vertical-align: middle;
}
</style>
