<template>
    <div class="location-filter-option">
        <country-select
            class="select"
            :shortCodeDropdown="false"
            placeholder="Select a country"
            v-on:input="changedCountry"
        ></country-select>

        <region-select
            :country="selected.country"
            class="select"
            :shortCodeDropdown="false"
            placeholder="Select a region"
            v-on:input="changedRegion"
        ></region-select>

        <input
            type="text"
            class="location_city_input"
            placeholder="Enter a city"
            v-model="selected.city"
            @input="onSelectChange"
        />
    </div>
</template>

<script>
import { CONDITION_TYPES } from "../../constants";
import CountrySelect from "../Settings/Items/CountrySelect";
import RegionSelect from "../Settings/Items/RegionSelect";

export default {
    name: "LocationFilterOption",
    props: ["onChange"],
    components: {
        CountrySelect,
        RegionSelect
    },
    data() {
        return {
            selected: {
                city: null,
                country: null,
                state: null
            }
        };
    },
    methods: {
        onSelectChange() {
            const { city, country, state } = this.selected;

            this.onChange({
                ...(country && { country }),
                ...(city && { city }),
                ...(state && { state })
            });
        },
        changedCountry(val) {
            this.selected.country = val;
            this.onSelectChange();
        },
        changedRegion(val) {
            this.selected.state = val;
            this.onSelectChange();
        }
    }
};
</script>

<style lang="less">
.location-filter-option {
    .select {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 5px;
        }
        input {
            width: 100%;
        }
    }

    .location_city_input {
        width: 100%;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #fff;
        background-image: none;
        border-radius: 4px;
        border: 1px solid rgb(191, 204, 217);
        box-sizing: border-box;
        color: rgb(31, 46, 61);
        display: inline-block;
        font-size: 12px;
        height: 32px;
        line-height: 1;
        outline: none;
        padding: 3px 10px;
        transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

        &::placeholder {
            color: #8d9fb5;
        }
    }
}
</style>