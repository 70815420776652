<template>
    <div class="date-filter-option">
        <el-select
                placeholder="Select a type"
                class="select"
                v-model="selectedCond"
                @change="onDateChange($event)"
        >
            <el-option
                    v-for="option in conditions"
                    :key="`select_option_${option.id}`"
                    :label="option.title"
                    :value="option.id"
            >
                <span class="select-option-text">{{ option.title }}</span>
            </el-option>
        </el-select>

        <div class="last" v-if="selectedCond === DATE_COND_TYPES.users.LAST.id">
            <img class="icon" src="../../assets/img/admin/enter-arrow.png" alt/>
            <el-input class="date-input" v-model="selected.lastNumber" @change="onDateChange($event)"/>
            <el-select
                    class="select-type"
                    v-model="selected.dateType"
                    @change="onDateChange($event)"
            >
                <el-option
                        v-for="option in DATE_TYPES"
                        :key="`select_option_${option.singular}`"
                        :label="option.plural"
                        :value="option.plural"
                >
                    <span class="select-option-text">{{ option.plural }}</span>
                </el-option>
            </el-select>
        </div>

        <div class="equal"
             v-if="selectedCond !== DATE_COND_TYPES.users.BT.id && selectedCond !== DATE_COND_TYPES.users.LAST.id">
            <img class="icon" src="../../assets/img/admin/enter-arrow.png" alt/>
            <el-date-picker
                    class="date-picker"
                    v-model="selected.date"
                    placeholder="Pick a date"
                    type="date"
                    @change="onDateChange($event)"
            />
        </div>

        <div class="equal" v-if="selectedCond === DATE_COND_TYPES.users.BT.id">
            <img class="icon" src="../../assets/img/admin/enter-arrow.png" alt/>
            <el-date-picker
                    class="date-picker-short"
                    v-model="selected.btDateFrom"
                    placeholder="Pick a date"
                    type="date"
                    @change="onDateChange($event)"
            />
            <span class="text">and</span>
            <el-date-picker
                    class="date-picker-short"
                    v-model="selected.btDateTo"
                    placeholder="Pick a date"
                    type="date"
                    @change="onDateChange($event)"
            />
        </div>
    </div>
</template>

<script>
    import moment from 'moment';
    import {DATE_COND_TYPES, DATE_TYPES} from '../../constants';

    export default {
        name: 'DateFilterOptionUsers',
        props: [
            'getData',
            'value',
            'onChange',
        ],
        data() {
            return {
                selected: {
                    dateType: DATE_TYPES.DAY.plural,
                    lastNumber: 0,
                    btDateFrom: null,
                    btDateTo: null,
                    date: null,
                },
                conditions: Object.values(DATE_COND_TYPES.users),
                selectedCond: DATE_COND_TYPES.users.LAST.id,
                DATE_COND_TYPES,
                DATE_TYPES,
            };
        },
        methods: {
            onDateChange() {
                const {
                    dateType,
                    lastNumber,
                    btDateFrom,
                    btDateTo,
                    date,
                } = this.selected;

                switch (true) {

                    case Boolean(this.selectedCond === DATE_COND_TYPES.users.LAST.id && lastNumber > 0):
                        this.onChange({
                            [DATE_COND_TYPES.users.LAST.value]: moment().subtract(lastNumber, dateType).format(),
                        });
                        return;

                    case Boolean(this.selectedCond === DATE_COND_TYPES.users.EQ.id && date):
                        this.onChange({
                            [DATE_COND_TYPES.users.EQ.value]: date,
                        });
                        return;

                    case Boolean(this.selectedCond === DATE_COND_TYPES.users.BT.id && date):
                        this.onChange({
                            [DATE_COND_TYPES.users.BT.value]: {
                                from: btDateFrom,
                                to: btDateTo,
                            },
                        });
                        return;

                    case Boolean(this.selectedCond === DATE_COND_TYPES.users.AFTER.id && date):
                        this.onChange({
                            [DATE_COND_TYPES.users.AFTER.value]: date,
                        });
                        return;

                    case Boolean(this.selectedCond === DATE_COND_TYPES.users.ON_AFTER.id && date):
                        this.onChange({
                            [DATE_COND_TYPES.users.ON_AFTER.value]: moment(date).subtract(1, 'days').format(),
                        });
                        return;

                    case Boolean(this.selectedCond === DATE_COND_TYPES.users.BEFORE.id && date):
                        this.onChange({
                            [DATE_COND_TYPES.users.BEFORE.value]: date,
                        });
                        return;

                    case Boolean(this.selectedCond === DATE_COND_TYPES.users.ON_BEFORE.id && date):
                        this.onChange({
                            [DATE_COND_TYPES.users.ON_BEFORE.value]: moment(date).add(1, 'days').format(),
                        });
                        return;

                    default:
                        return;
                }
            }
        }
    }
</script>

<style lang="less">
    .el-picker-panel {
        width: 300px;

        tr td:first-child, tr th:first-child {
            padding-left: 0px;
        }
    }

    .date-filter-option {
        .icon {
            width: 13px;
            height: 12px;
            margin-left: 5px;
            margin-right: 10px;
        }

        .select {
            width: 100%;
            text-transform: capitalize;

            input {
                width: 100%;
            }

            .select-option-text {
                text-transform: capitalize;
            }
        }

        .last {
            margin-top: 10px;
            display: flex;
            align-items: center;

            .date-input {
                margin-right: 10px;
            }

            .date-input > input {
                width: inherit;
            }

            .select-type input {
                width: inherit;
            }
        }

        .equal {
            display: flex;
            margin-top: 10px;
            align-items: center;

            .text {
                font-size: 11px;
                color: #A3BAC6;
                padding-left: 9px;
                padding-right: 9px;
            }

            .date-picker-short, .date-picker-short input {
                width: 94px;
            }
        }
    }
</style>