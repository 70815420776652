<template>
    <div>
        <date-filter-option-users
                v-if="variant === 'users'"
                :get-data="getData"
                :on-change="onChange"
        />
        <date-filter-option-projects
                v-if="variant === 'projects'"
                :get-data="getData"
                :on-change="onChange"
                :type="type"
                :title="title"
        />
    </div>
</template>

<script>
    import DateFilterOptionUsers from './DateFilterOptionUsers';
    import DateFilterOptionProjects from './DateFilterOptionProjects';

    export default {
        name: 'DateFilterOption',
        components: {
            DateFilterOptionUsers,
            DateFilterOptionProjects,
        },
        props: {
            getData: Function,
            onChange: Function,
            variant: {type: String, required: true},
            type: {type: String},
            title: {type: String}
        },
    }
</script>
