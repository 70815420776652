<template>
  <svg
    id="Checked"
    xmlns="http://www.w3.org/2000/svg"
    width="17.371"
    height="17.371"
    viewBox="0 0 17.371 17.371"
  >
    <path
      id="Rectangle-4-Copy-6"
      class="cls-1"
      d="M24.343,152.5h8.686a4.343,4.343,0,0,1,4.343,4.343v8.686a4.343,4.343,0,0,1-4.343,4.343H24.343A4.343,4.343,0,0,1,20,165.528v-8.686A4.343,4.343,0,0,1,24.343,152.5Z"
      transform="translate(-20 -152.5)"
    />
    <path
      id="Path_8230"
      data-name="Path 8230"
      class="cls-2"
      d="M8.951,68.131a.454.454,0,0,0-.642,0L2.867,73.573.775,71.481a.454.454,0,0,0-.642.642l2.413,2.413a.454.454,0,0,0,.642,0l5.763-5.763A.454.454,0,0,0,8.951,68.131Z"
      transform="translate(4.001 -62.764)"
    />
  </svg>
</template>
<script>
export default {
  name: "CheckedIcon"
};
</script>
<style lang="less" scoped>
.cls-1 {
  fill: #21455e;
  fill-rule: evenodd;
}

.cls-2 {
  fill: #fff;
  stroke: #fff;
  stroke-width: 0.2px;
}
</style>