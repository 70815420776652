<template>
    <el-select
        :class="className"
        :id="id"
        :placeholder="placeholder"
        :value="selCountry"
        @change="onChange()"
        filterable
        v-model="selCountry"
        class="custom_select"
        popper-class="custom_select_pop admin_select_pop location_select_pop"
        :disabled="onAdminAccountInfo"
    >
        <el-option :label="topCountryName()" :value="firstCountry" v-if="topCountry"></el-option>

        <el-option
            :key="`countries_${index}`"
            :label="shortCodeDropdown ? region.countryShortCode : region.countryName"
            :value="region[valueType]"
            v-for="(region, index) in countries"
        ></el-option>
    </el-select>
</template>

<script>
    export default {
        name: "CountrySelect",
        props: {
            topCountry: String,
            countryName: Boolean,
            whiteList: Array,
            blackList: Array,
            id: String,
            className: String,
            shortCodeDropdown: Boolean,
            placeholder: {
                type: String,
                default: "Select Country"
            }
        },
        data: () => ({
            selCountry: ""
        }),
        computed: {
            countries() {

                let countryList = this.$store.state.countries.filter(
                    region => {
                        if (this.countryName) {
                            return region.countryName !== this.firstCountry;
                        } else {
                            return region.countryShortCode !== this.firstCountry;
                        }
                    }
                );

                if (this.whiteList) {
                    countryList = countryList.filter(country => {
                        return this.whiteList.includes(country.countryShortCode);
                    });
                }

                if (this.blackList) {
                    countryList = countryList.filter(country => {
                        return !this.blackList.includes(country.countryShortCode);
                    });
                }

                return countryList;
            },
            firstCountry() {
                if (this.countryName) {
                    if (this.topCountry.length === 2) {
                        const regionObj = this.$store.state.countries.find(
                            region => {
                                return region.countryShortCode === this.topCountry;
                            }
                        );

                        return regionObj.countryName;
                    } else {
                        return this.topCountry;
                    }
                }

                if (this.topCountry) {
                    return this.topCountry;
                }

                return "";
            },
            valueType() {
                return this.countryName ? "countryName" : "countryShortCode";
            },
            onAdminAccountInfo: function() {
                return this.$route.path.includes('account-info')
            }
        },
        created() {
            this.selCountry = this.topCountry;
        },
        methods: {
            onChange() {
                this.$emit("input", this.selCountry);
            },

            topCountryName() {
                const regionObj = this.$store.state.countries.find(
                    region => {
                        if (this.countryName) {
                            return region.countryName === this.firstCountry;
                        } else {
                            return region.countryShortCode === this.firstCountry;
                        }
                    }
                );

                return this.shortCodeDropdown
                    ? regionObj.countryShortCode
                    : regionObj.countryName;
            }
        }
    };
</script>
