<template>
    <div>
        <title-filter-option-projects
                v-if="variant === 'projects'"
                :get-data="getData"
                :on-change="onChange"
                :title="title"
        />
    </div>
</template>

<script>

    import TitleFilterOptionProjects from './TitleFilterOptionProjects';

    export default {
        name: 'TitleFilterOption',
        props: {
            getData: Function,
            onChange: Function,
            variant: {type: String, required: true},
            title: {type: String},
            value: String
        },

        components: {
            TitleFilterOptionProjects,
        },
    }
</script>