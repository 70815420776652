<template>
    <el-select
        :class="className"
        :placeholder="placeholder"
        :value="region"
        @change="onChange()"
        filterable
        v-model="region"
        class="custom_select"
        popper-class="custom_select_pop admin_select_pop location_select_pop"
        :disabled="onAdminAccountInfo"
    >
        <el-option
            :key="`regions_${index}`"
            :label="shortCodeDropdown ? place.shortCode : place.name"
            :value="place[valueType]"
            v-for="(place, index) in shownRegions"
        ></el-option>
    </el-select>
</template>

<script>
export default {
    name: 'RegionSelect',
    props: {
        country: String,
        defaultRegion: String,
        countryName: Boolean,
        regionName: Boolean,
        className: String,
        shortCodeDropdown: Boolean,
        placeholder: {
            type: String,
            default: 'Select Region'
        }
    },
    data: () => ({
        shownRegions: [],
        region: ''
    }),
    computed: {
        countries() {
            return this.$store.state.countries;
        },
        valueType() {
            return this.regionName ? 'name' : 'shortCode';
        },
        onAdminAccountInfo: function() {
            return this.$route.path.includes('account-info');
        }
    },
    mounted() {
        if (this.country) {
            this.getRegionWithCountry();
        } else {
            let findRegion = '';

            if (this.countryName) {
                findRegion = this.defaultRegion
                    ? this.defaultRegion
                    : 'United States';
            } else {
                findRegion = this.defaultRegion ? this.defaultRegion : 'US';
            }

            this.getRegionWithCountry(findRegion);
        }

        this.region = this.defaultRegion;
    },
    methods: {
        onChange() {
            this.$emit('input', this.region);
        },

        getRegionWithCountry(country) {
            country = country || this.country;

            this.shownRegions = this.countries.find(elem => {
                if (this.countryName) {
                    return elem.countryName === country;
                } else {
                    return elem.countryShortCode === country;
                }
            }).regions;
        }
    },
    watch: {
        country(newVal, oldVal) {
            if (oldVal !== '') {
                this.region = '';

                this.onChange();
            }

            if (this.country) {
                this.getRegionWithCountry();
            } else {
                this.shownRegions = [];
            }
        }
    }
};
</script>
