<template>
    <div>
        <condition-filter-option-users
            v-if="variant === 'users'"
            :get-data="getData"
            :on-change="onChange"
            :type="type"
        />
        <condition-filter-option-projects
            v-if="variant === 'projects'"
            :get-data="getData"
            :on-change="onChange"
            :title="title"
        />
    </div>
</template>

<script>

    import ConditionFilterOptionUsers from './ConditionFilterOptionUsers';
    import ConditionFilterOptionProjects from './ConditionFilterOptionProjects';

    export default {
        name: 'ConditionFilterOption',
        props: {
            getData: Function,
            onChange: Function,
            variant: {type: String, required: true},
            title: {type: String},
            type: {type: String},
        },

        components: {
            ConditionFilterOptionUsers,
            ConditionFilterOptionProjects,
        }
    }
</script>