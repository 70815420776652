<template>
    <div class="title-filter-option-projects">
        <el-select
                placeholder="Select"
                class="select"
                v-model="selected"
                @change="onSelectChange($event)"
        >
            <el-option
                    v-for="option in options"
                    :key="`select_option_${option.id}`"
                    :label="option.title"
                    :value="option.id"
            >
                <span class="select-option-text">{{ option.title }}</span>
            </el-option>
        </el-select>

        <div class="title-row">
            <img class="icon" src="../../assets/img/admin/enter-arrow.png" alt/>
            <el-input class="from" v-model="inputValue" @change="onInputChange('inputValue')($event)"/>
        </div>

    </div>
</template>

<script>

    import {TITLE_TYPES} from '../../constants';

    export default {
        name: 'TitleFilterOptionProjects',
        props: [
            'getData',
            'value',
            'onChange',
            'title'
        ],
        data() {
            return {
                options: Object.values(TITLE_TYPES),
                selected: TITLE_TYPES.IS.id,
                TITLE_TYPES,
                inputValue: '',
            };
        },
        methods: {
            onSelectChange($event) {
                const {inputValue} = this;
                this.onChange(
                    this.title.toLowerCase(),
                    {
                        action: $event,
                        value: inputValue
                    }
                );
            },
            onInputChange(name) {
                const {selected} = this;
                return $event => this.onChange(
                    this.title.toLowerCase(),
                    {
                        action: selected,
                        value: $event
                    }
                );
            }
        }
    }
</script>

<style lang="less">
    .title-filter-option-projects {
        .select {
            width: 100%;

            input {
                width: 100%;
            }
        }

        .title-row {
            display: flex;
            align-items: center;
            margin-top: 10px;

            .icon {
                min-width: 13px;
                height: 12px;
                margin-right: 10px;
                margin-left: 5px;
            }

            .from, .to {
                input {
                    width: inherit;
                }
            }

            .text {
                font-size: 11px;
                color: #A3BAC6;
                padding-left: 9px;
                padding-right: 9px;
            }
        }
    }
</style>