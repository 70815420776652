<template>
  <div class="condition-filter-option">
    <div class="condition-select">
      <el-select
        placeholder="Select"
        class="select"
        v-model="selected"
        @change="onSelectChange($event)"
      >
        <el-option
          v-for="option in options"
          :key="`select_option_${option.value}`"
          :label="option.title"
          :value="option.value"
        >
          <span class="select-option-text">{{ option.title }}</span>
        </el-option>
      </el-select>
      <el-select v-model="sizeScaleSelected" class="size-select" @change="onChangeSize($event)" v-if="type === 'size'">
        <el-option :key="item.value" :label="item.label" :value="item.value" v-for="item in sizeScales" />
      </el-select>
    </div>

    <div class="condition-row" v-if="CONDITION_TYPES.BT.value === selected">
      <img class="icon" src="../../assets/img/admin/enter-arrow.png" alt />
      <el-input class="from" v-model="from" @change="onInputChange('from')($event)" />
      <span class="text">and</span>
      <el-input class="to" v-model="to" @change="onInputChange('to')($event)" />
    </div>

    <div class="condition-row" v-if="CONDITION_TYPES.BT.value !== selected">
      <img class="icon" src="../../assets/img/admin/enter-arrow.png" alt />
      <el-input class="from" v-model="from" @change="onInputChange('from')($event)" />
    </div>

  </div>
</template>

<script>
import { CONDITION_TYPES, SIZE_SCALES } from '../../constants';

export default {
  name: 'ConditionFilterOptionUsers',
  props: [
    'getData',
    'value',
    'onChange',
    'type',
  ],
  data() {
    return {
      options: Object.values(CONDITION_TYPES),
      selected: CONDITION_TYPES.EQ.value,
      CONDITION_TYPES,
      sizeScales: Object.keys(SIZE_SCALES).map(size => ({ label: SIZE_SCALES[size], value: size })),
      from: '',
      to: '',
      sizeScaleSelected: Object.keys(SIZE_SCALES)[0],
    };
  },
  methods: {
    calcValue (value = 0) {
      if (this.type !== 'size') {
        return value;
      }

      const [MB, GB, TB] = Object.keys(SIZE_SCALES);
      switch (this.sizeScaleSelected) {
        case GB:
          return Number(parseFloat(value) * 1024).toFixed(2);
        case TB:
          return Number(parseFloat(value) * 1024 * 1024).toFixes(2);
        case MB:
        default:
          return parseFloat(value);
      }
    },
    onSelectChange($event) {
      const { from, to } = this;
      this.onChange({
        [$event]: CONDITION_TYPES.BT.value === $event
          ? { from: this.calcValue(from), to: this.calcValue(to) }
          : this.calcValue(from),
      });
    },
    onInputChange(name) {
      const { selected } = this;
      const oppositeName = name === 'from' ? 'to' : 'from';

      return $event => this.onChange({
        [selected]: CONDITION_TYPES.BT.value === selected
          ? { [name]: this.calcValue($event), [oppositeName]: this.calcValue(this[oppositeName]) }
          : this.calcValue($event),
      });
    },
    onChangeSize($event) {
      const { from, to, selected } = this;
      const isBetween = CONDITION_TYPES.BT.value === selected;

      if ((!isBetween && !from.length) || (isBetween && !from.length && !to.length)) {
        return;
      }

      this.onChange({
        [selected]: CONDITION_TYPES.BT.value === selected
          ? { from: this.calcValue(from), to: this.calcValue(to) }
          : this.calcValue(from),
      });
    }
  }
}
</script>

<style lang="less">
  .condition-filter-option {
    .select {
      width: 100%;
      input {
        width: 100%;
      }
    }

    .condition-select {
      display: flex;
    }

    .size-select {
      margin-left: 5px;
      width: 68px;

      input {
        width: 68px;
      }
    }

    .condition-row {
      display: flex;
      align-items: center;
      margin-top: 10px;

      .icon {
        min-width: 13px;
        height: 12px;
        margin-right: 10px;
        margin-left: 5px;
      }

      .from, .to {
        input {
          width: inherit;
        }
      }

      .text {
        font-size: 11px;
        color: #A3BAC6;
        padding-left: 9px;
        padding-right: 9px;
      }
    }
  }
</style>