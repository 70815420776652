<template>
  <div class="select-filter-option">
    <el-select
      v-model="selected"
      placeholder="Select"
      class="select"
      @change="change($event)"
    >
      <el-option
        v-for="option in options"
        :key="`select_option_${option.value}`"
        :label="option.title"
        :value="option.value"
      >
        <span class="select-option-text">{{ option.title }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'SelectFilterOption',
  props: [
    'value',
    'onChange',
    'getData',
  ],
  data() {
    return {
      options: [],
      selected: null,
    };
  },
  mounted() {
    this.getData().then((options) => {
      this.options = options;
    });
  },
  methods: {
    change() {
      this.onChange(this.selected);
    }
  }
}
</script>

<style lang="less">
  .select-filter-option {
    .select {
      width: 100%;
      input {
        width: 100%;
      }
    }
  }
</style>