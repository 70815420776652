<template>
  <div class="button-container">
    <el-popover
      :visible-arrow="true"
      placement="bottom"
      trigger="manual"
      popper-class="custom_popover pop_bottom_270 project-popover"
      v-model="show"
    >
      <button @click="show = !show" slot="reference" class="multiple-select-button">
        <slot name="icon"></slot>
        <span class="sort-by__value">{{ buttonName }}</span>
        <slot name="circle"></slot>
        <arrow-down-icon></arrow-down-icon>
      </button>

      <slot name="selectOptions"></slot>

      <div class="divided"></div>
      <div class="buttons-cancel-done">
        <button class="button button-cancel" @click="handleCancel($event)">Cancel</button>
        <button class="button button-done" @click="handleDone($event)">Done</button>
      </div>
    </el-popover>
  </div>
</template>
<script>
import ArrowDownIcon from "../SvgComponents/ArrowDownIcon";
import { mapState } from "vuex";

export default {
  name: "MultipleSelectPopover",
  components: {
    ArrowDownIcon
  },
  watch: {
    visible: function(newVal, oldVal) {
      newVal ? null : this.handleCancel();
    }
  },
  data: () => {
    return {
      show: false
    };
  },
  props: ["buttonName", "onCancel", "onDone", "visible"],
  methods: {
    handleCancel($event) {
      this.onCancel();
      this.$emit("cancel-pushed");
      this.show = false;
    },
    handleDone($event) {
      this.onDone();
      this.$emit("done-pushed");
      this.show = false;
    }
  }
};
</script>
<style lang="less" scoped>
.divided::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #e8e8ea;
  display: block;
  position: relative;
}

.buttons-cancel-done {
  height: 55px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  .button {
    border-radius: 20px;
    color: #ffffff;
    border: none;
    width: 84px;
    height: 32px;
    font-size: 14px;
    font-weight: 300;
    font-family: "Helvetica Neue";
  }

  .button:focus {
    outline: none;
  }

  .button-cancel {
    background-color: #707070;
  }

  .button-done {
    background-color: #00acdc;
  }
}

.button-container {
  flex-basis: 100%;
}

.button-container > span {
  display: flex;
  flex-basis: 100%;
}

.el-button > span {
  display: flex;
  flex-basis: 100%;
}

.multiple-select-button {
  min-width: 110px;
  border-radius: 20px;
  background-color: #f5f6f9;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-overflow: ellipsis;
  border: none;
  padding: 3px 14px;
  color: #778d9d;
}

.multiple-select-button:focus,
.multiple-select-button:active {
  outline: none;
}

.multiple-select-button:active {
  border: 1px solid #21455e;
}

.sort-by__value {
  font-size: 13px;
}
</style>
