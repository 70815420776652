<template>
  <div class="filter-option">
    <input class="checkbox-input" type="checkbox" :checked="checked" @click="onCheck(item)" />
    <label class="checkbox-text" :for="item.title" @click="onCheck(item)">
      <div class="checked-icon">
        <checked-icon v-if="checked"></checked-icon>
        <div class="checked-icon__empty" v-else></div>
      </div>
      {{ item.title }}
    </label>
    <select-filter-option
      v-if="checked && type === FILTER_TYPES.SELECT"
      :get-data="getData"
      :on-change="onChange"
    />
    <condition-filter-option
      v-if="checked && type === FILTER_TYPES.CONDITIONS"
      :get-data="getData"
      :on-change="onChange"
      :variant="forList"
      :title="title"
      :type="dataType"
    />
    <title-filter-option
      v-if="checked && type === FILTER_TYPES.TITLE"
      :get-data="getData"
      :on-change="onChange"
      :variant="forList"
      :title="title"
    />
    <location-filter-option v-if="checked && type === FILTER_TYPES.LOCATION" :on-change="onChange" />
    <date-filter-option
      v-if="checked && type === FILTER_TYPES.DATE"
      :get-data="getData"
      :on-change="onChange"
      :variant="forList"
      :title="title"
    />
  </div>
</template>

<script>
import SelectFilterOption from "./SelectFilterOption";
import ConditionFilterOption from "./ConditionFilterOption";
import TitleFilterOption from "./TitleFilterOption";
import LocationFilterOption from "./LocationFilterOption";
import DateFilterOption from "./DateFilterOption";
import { FILTER_TYPES } from "../../constants";
import CheckedIcon from '../Admin/SvgComponents/CheckedIcon';

export default {
  name: "FilterOption",
  components: {
    SelectFilterOption,
    ConditionFilterOption,
    LocationFilterOption,
    TitleFilterOption,
    DateFilterOption,
    CheckedIcon
  },
  props: {
    type: { type: String, required: true },
    title: { type: String, required: true },
    item: { type: Object, required: true },
    checked: { type: Boolean, required: false },
    onCheck: { type: Function, require: true },
    getData: Function,
    onChange: Function,
    forList: { type: String, required: true },
    dataType: { type: String }
  },
  data() {
    return {
      FILTER_TYPES
    };
  }
};
</script>

<style scoped>
  .filter-option >>> .el-input__inner {
    font-size: 12px;
    font-weight: 500;
    border: 1px solid #e2e5ed;
    height: 32px;
    width: 190px;
  }
</style>

<style lang="less" scoped>
.filter-option {
  padding: 7px;
  cursor: pointer;

  .checkbox-input {
    display: none;
  }

  .checked-icon {
    margin-right: 15px;
    display: flex;
    align-items: center;

    &__empty {
      width: 17.4px;
      height: 17.4px;
      border: 1px solid#A3BAC6;
      border-radius: 5px;
    }
  }

  .checkbox-text {
    font-size: 14px;
    font-weight: normal;
    display: flex;
    align-items: center;
  }

//   .checkbox-input:checked + label::before {
//     background-image: url("../../assets/img/admin/checked.png");
//     background-size: cover;
//     border: none;
//   }

//   .checkbox-text::before {
//     content: "";
//     display: block;
//     width: 20px;
//     height: 20px;
//     border-radius: 9%;
//     border: 1px solid #21455e;
//     margin-right: 7px;
//   }
}


</style>